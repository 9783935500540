import React from "react"
import { graphql } from "gatsby"
import InfoCard from "../components/common/InfoCard.jsx"
import Layout from "../components/layout"
import Title from "../components/common/Title.jsx"
import CardColumns from "react-bootstrap/CardColumns"
import { Link } from "gatsby"
import styled from "styled-components"

const Style = styled.div`

.card-columns {
@media (min-width: 576px){    
  -webkit-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;}
}
`
const DestinationList = ({ data }) => {
  return (
    <Style>
      <Layout>
      <Title title="Destination sorted by Region" />
      <CardColumns>
      {data.allRegionsJson.edges.map(({node }, key) => {
          return (
            <Link key={key} to='/regions'>
              <InfoCard name={node.name} image={node.cardPicture} shortDescription={node.shortDescription} />
            </Link>
          );
        })}

      </CardColumns>
    </Layout>
    </Style>
  )
}

export const query = graphql`
  query {
    allRegionsJson {
      edges {
        node {
          name
          shortDescription
          cardPicture
          countries {
            name
            absolutePath
          }
        }
      }
    }
  }
`

export default DestinationList
