/* eslint-disable react/prop-types */
import React from "react"
import styled from "styled-components"
import Card from "react-bootstrap/Card"

const Style = styled.div`
  .card {
        margin: 0 auto;
        float: none;
        margin-bottom: 10px;            
}
`
class InfoCard extends React.Component {
  render() {
    return (
      <Style>
        <Card>
          <Card.Img
            variant="top"
            src={this.props.image}
          />
          <Card.Body>
            <Card.Title>{this.props.name}</Card.Title>
            <Card.Text>
              {this.props.shortDescription}
            </Card.Text>
          </Card.Body>
        </Card>
      </Style>
    )
  }
}
export default InfoCard
